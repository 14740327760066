import Vue from "vue";
import App from "./App.vue";
import router from "./router";
//import acl from './acl';
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import Back from "@/core/services/back.service";
import MockService from "@/core/mock/mock.service";
import { APP_VERIFY_AUTH } from "@/core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import VueQuillEditor from 'vue-quill-editor'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.use(VueQuillEditor, /* { default global options } */)


Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import 'vue-search-select/dist/VueSearchSelect.css'

// API service init
ApiService.init();
Back.init();
// Remove this to disable mock API
MockService.init();
/*if(Back.user === null){
  router.push({ name: "login" });
}*/
router.beforeEach((to, from, next) => {
  const user = Back.getUser();
  if(user === null){
    store.role = 'isUser';
  }else{
    store.role = user.role == 1 ? 'isAdmin' : 'isUser'
  }
  if(to.meta.rule === 'isAdmin' && store.role != 'isAdmin'){
    router.push({path:'/accueil'})
  }

  //console.log(from)
  Promise.all([store.dispatch(APP_VERIFY_AUTH)]).then(next);

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);
  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

new Vue({
  router,
  //acl,
  store,
  i18n,
  vuetify,
  render: h => h(App),
  beforeMount() {
    Back.toast = this.$bvToast;
  }
}).$mount("#app");
