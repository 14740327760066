import BackService from "@/core/services/back.service";
//import store from "@/core/services/store/store";
// action types
export const APP_VERIFY_AUTH = "appVerifyAuth";
export const APP_LOGIN = "appLogin";
export const APP_LOGOUT = "appLogout";
export const APP_REGISTER = "appRegister";
export const APP_UPDATE_USER = "appUpdateUser";

// mutation types
export const APP_PURGE_AUTH = "appLogOut";
export const APP_SET_AUTH = "appSetUser";
export const APP_SET_ERROR = "appSetError";

const state = {
  errors: null,
  user: {
    email: null,
    expire_at: null,
    nom: null,
    prenom: null,
    role: null,
    token: null
  },
  isAuthenticated: !!BackService.getToken(),
  role:'isUser'
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [APP_LOGIN](context, credentials) {
    return new Promise(resolve => {
      BackService.ajax('/authorization/create',credentials, (res) => {
        if(res.status === true){
          context.commit(APP_SET_AUTH,res.data);
          resolve(res.data);
        }else{
          context.commit(APP_SET_ERROR,res.data)
        }
      })
    })
  },
  [APP_LOGOUT](context) {
    context.commit(APP_PURGE_AUTH);
  },
  [APP_REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      BackService.ajax('/test/ping',null, (res) => {
        console.log(credentials);
        if(res.status === true){
          //context.commit(APP_SET_ERROR, res.data);
          resolve(res.data);
        }else{
          context.commit(APP_SET_ERROR, res.data);
          reject(res.data);
        }
      });
    });
  },
  [APP_VERIFY_AUTH](context) {
    const token = BackService.getToken();
    const user = BackService.getUser();
    if(token && user){
      context.commit(APP_SET_AUTH,user)
    }else if (token) {
      BackService.ajax('/authorization/verify',null, (res) => {
        if(res.status === true){
          context.commit(APP_SET_AUTH,res.data)
        }
      });
    }else {
      context.commit(APP_PURGE_AUTH);
    }
  },
  [APP_UPDATE_USER](context, credentials) {
    return new Promise((resolve, reject) => {
      BackService.ajax('/test/ping',null, (res) => {
        console.log(credentials);
        if(res.status === true){
          context.commit(APP_SET_ERROR, res.data);
          resolve(res.data);
        }else{
          context.commit(APP_SET_ERROR, res.data);
          reject(res.data);
        }
      });
    })
  }
};

const mutations = {
  [APP_SET_ERROR](state, error) {
    state.errors = error;
  },
  [APP_SET_AUTH](state, user) {
    state.isAuthenticated = true;
    //store.state.user = user;
    state.user = user;
    state.errors = {};
    BackService.saveToken(state.user.token);
    BackService.saveUser(state.user);
  },
  [APP_PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    BackService.destroyToken();
    BackService.destroyUser();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
